/************************************************************************************************
 *
 * Variables
 *
 ***********************************************************************************************/

$color-blue: #0f4b93;
$color-lightblue: #1cc1e1;
$color-white: #ffffff;
$color-black: #131718;
$color-grey: #bdc1c6;
$color-lightgrey: #f0f0f2;

// assignment
$color-error: red;
$color-selection: $color-lightblue;
$color-selection-text: $color-white;
$color-text: $color-black;
$color-borders: $color-blue;
$color-placeholder: $color-grey;

// type
$font-sans: 'din-2014', 'Helvetica Neue', Arial, sans-serif;

// width of site
$site-width: 75em;

// breakpoints used by media query mixins
// NOTE: If these values are changed, the comments in _grid.scss must also be changed
$xs: 20em;
$s: 30em;
$m: 45em;
$l: 65em;
$xl: 95em;
$xxl: 120em;

// font-sizes

//fallbak vars
$heading-hero: 3.75rem;
$heading-section: 2.375rem;
$heading-card: 1.75rem;

// CSS custom properties
:root {
	--heading-hero: 2.25rem;
	--heading-section: 1.5rem;
	--heading-card: 1.5rem;

	@include mq($l) {
		--heading-hero: 3.75rem;
		--heading-section: 2.375rem;
		--heading-card: 1.75rem;
	}
}
