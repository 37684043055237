.hidden {
	display: none !important;
	visibility: hidden;
  &--xs {
    @include mq(0, $s) {
      display: none !important;
      visibility: hidden;
    }
  }
  &--s {
    @include mq($s, $m) {
      display: none !important;
      visibility: hidden;
    }
  }
  &--m {
    @include mq($m, $l) {
      display: none !important;
      visibility: hidden;
    }
  }
  &--l {
    @include mq($l, $xl) {
      display: none !important;
      visibility: hidden;
    }
  }
  &--xl {
    @include mq($xl, $xxl) {
      display: none !important;
      visibility: hidden;
    }
  }
  &--xxl {
    @include mq($xxl) {
      display: none !important;
      visibility: hidden;
    }
  }
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	*zoom: 1;
}
