.about-tile {
	display: flex;
	align-items: flex-start;

	@include mq($l) {
		flex-direction: column;
	}

	&__img {
		display: block;
		margin: 0 2em 0 0;
		max-width: 3em;

		@include mq($l) {
			max-width: 150px;
			margin-bottom: 2em;
		}
	}
	
	&__body{
		width:100%;

		> * {
			width: 100%;
		}
	}
}

