.sidebar-card {
	padding: 1.5em 2em;
	background-color: $color-lightgrey;

	&--rounded {
		border-radius: 0.25em;
	}

	&--alt {
		background-color: $color-blue;
		color: $color-white;
	}
}
