.team-grid {
	&__person {
		display: flex;
		align-items: flex-start;
		overflow: hidden;

		@include mq($l) {
			flex-direction: column;
		}

		.btn--link {
			display: inline-block;

			@include mq(0, $l) {
				> span {
					border: 0;
					clip: rect(0 0 0 0);
					height: 1px;
					margin: -1px;
					overflow: hidden;
					padding: 0;
					position: absolute;
					width: 1px;
				}
				&:before {
					content: attr(data-email);
				}
			}
		}
	}

	.byline,
	.btn--link {
		font-size: 0.8em;
	}

	&__image {
		max-width: 7.5em;
		margin-right: 1em;
		flex-shrink: 0;

		@include mq($l) {
			max-width: 100%;
			margin-right: 0;
			margin-bottom: 1em;
		}
	}
}
