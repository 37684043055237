.contact-bar {
	align-items: center;
	background-color: $color-lightblue;
	color: $color-white;
	display: flex;
	justify-content: flex-end;
	padding: 0.5em 1em;

	@include mq($m) {
		padding: 0.5em 2em;
	}

	&__tel {
		color: $color-blue;
	}

	&__intro {
		@include mq(0, $s) {
			display: none;
		}
	}
}
