.contact-section {
	padding-bottom: 0;
	padding-top: 0;
	.wrapper {
		padding-right:0;
		background-color: $color-blue;
		color: $color-white;
		h2 {
			padding-top:10%;
		}
		img {
			margin-left: -20px;
			max-width:105%;
		}
	}
	p {
		font-size: $heading-card;
		font-size: var(--heading-card);
		line-height: 1.5;
		color: $color-lightblue;
		padding-bottom: 30px;
	}

	a {
		color: $color-lightblue;
	}

	&__img {
		margin-top: 2em;
		@include mq($m) {
			margin-top: 0;
			padding-left: 0;
		}
	}
}
