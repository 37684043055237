.smallprint {
	font-size: 0.8em;

	strong {
		text-transform: uppercase;
	}

	> * + * {
		margin-top: 0.5em;
	}
}
