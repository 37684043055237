.hero {
	position: relative;
	/* background-color: $color-blue; */
	color: $color-white;
	
    background-repeat: no-repeat;
    
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(10, 10, 10, 0.5);

	.hero__title {
		margin: 1em 0 2em;
	}

	.hero__image {
		z-index: 1;
	}

}
.hero--large {
	padding-top: 160px;
	background-position-y: -65px;
}
.hero--small {
	padding-top: 110px;
	background-position-y: -30px;
}

@media only screen and (max-width: 719px) {
	.hero {
		background-position-y: 0px!important;
    	padding-top: 75px!important;
	}
}