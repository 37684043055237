/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

*,
*:before,
*:after {
	box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
	display: block;
}
body {
	background: #ffffff;
	font-size: 16px;
	line-height: 1.3;

	@include mq($m) {
		font-size: 18px;
	}

	@include mq($l) {
		font-size: 20px;
	}
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
	width: 100%;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	font-family: $font-sans;
	font-size: 100%;
	color: $color-text;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid;
	margin: 1em 0;
	padding: 0;
}

img {
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

a {
	color: $color-blue;
	text-decoration: none;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

::-moz-selection {
	background: $color-selection;
	color: $color-selection-text;
	text-shadow: none;
}

::selection {
	background: $color-selection;
	color: $color-selection-text;
	text-shadow: none;
}
