.site-header {
	align-items: center;
	/* background-color: $color-blue; */
	color: $color-white;
	display: flex;
	justify-content: space-between;
	padding: 4.5em 2em;
	position: absolute;
    z-index: 1000;
    width: 100%;

	@include mq($m) {
		/* padding: 2em 2em; */
	}
}

@media only screen and (max-width: 719px) {
	.site-header {
		padding: 4.5em 1.2em;
		display: flow;
	}
}