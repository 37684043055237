.contact-box {
	dt,
	dd {
		float: left;
	}

	dt {
		clear: left;
		font-weight: 400;
		margin-right: 0.5em;
	}
	dd {
		clear: right;
	}

	dl {
		overflow: hidden;
	}

	&__address {
		clear: both;
		width: 100%;
		margin-top: 2em;
		font-size: 0.9em;
	}
}
