.btn {
	background: $color-lightblue;
	border: 0 none;
	border-radius: 3px;
	color: $color-white;
	display: inline-block;
	font-family: $font-sans;
	font-size: 1.125em;
	font-weight: 400;
	padding: 1rem 1.5rem;
	text-align: center;

	&--inline {
		padding: 0.65rem 1rem;
		text-transform: uppercase;
	}
	&--email-signup {
		padding: 0.6rem 1.5rem;
	}
}
