.body {
	margin-bottom: $heading-hero;
	margin-bottom: var(--heading-hero);
	* + * {
		margin-top: 1.5em;
	}

	h2,
	h3,
	h4,
	h5,
	h5 {
		font-size: $heading-card;
		font-size: var(--heading-card);
	}

	ul li {
		position: relative;
		padding-left: 1.25em;
		&:before {
			content: '■';
			position: absolute;
			left: 0;
			top: 0;
			color: $color-lightblue;
			margin-right: 1em;
			line-height: 1;
		}
	}
}
