.site-nav {
	display: flex;
	flex-direction: row;
	justify-content: right;
	position: relative;
	top: 0;
	left: 0;
	z-index: -1;
	visibility: hidden;
	width: 100%;
	height: 100%;
	/* background: $color-blue; 
	align-items: center; */

	&.is-open {
		z-index: 5;
		visibility: visible;
	}

	@include mq($m) {
		flex-direction: row;
		position: static;
		width: auto;
		visibility: visible;
		z-index: auto;
	}

	&__toggle {
		background: transparent;
		border: 0 none;
		width: 2.2em;
		height: 1.7em;
		float: right;
		padding: 0;
		position: relative;

		.site-nav__toggle-line {
			background: $color-white;
			display: block;
			height: 3px;
			transition: transform 0.2s ease;
		}

		&:hover,
		&:focus {
			outline: 0 none;
			.site-nav__toggle-line {
				background: $color-lightblue;
			}
			&:before,
			&:after {
				border-color: $color-lightblue;
			}
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
			transform-origin: 2%;
			transition: transform 0.2s ease;
		}

		&:before {
			top: 0;
			border-top: 3px solid $color-white;
		}

		&:after {
			bottom: 0;
			border-top: 3px solid $color-white;
		}

		.js-mobile-nav-open & {
			position: fixed;
			z-index: 6;
			right: 1.5em;
			top: 5.5em;

			.site-nav__toggle-line {
				transform: rotateX(90deg);
			}

			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}
		@include mq($m) {
			display: none;
		}
	}

	> a {
		color: $color-white;
		display: block;
		font-size: 2rem;
		padding: 1em 1em;
		position: relative;
		text-align: center;

		&:hover,
		&:focus {
			text-decoration: none;
		}

		&:after {
			background: transparent;
			border-radius: 2px;
			content: '';
			height: 4px;
			position: absolute;
			top: calc(100% - 0.5em);
			left: 1em;
			width: calc(100% - 2em);
			transition: background-color 0.2s ease;

			@include mq($m) {
				top: 100%;
			}
		}
		&:hover,
		&:focus {
			&:after {
				background: $color-lightblue;
			}
		}

		@include mq($m) {
			font-size: 1rem;
			padding: 0.25em 1em;
		}
	}

	&--home {
		> a:nth-child(1):after {
			background: $color-lightblue;
		}
	}
	&--about {
		> a:nth-child(2):after {
			background: $color-lightblue;
		}
	}
	&--team {
		> a:nth-child(3):after {
			background: $color-lightblue;
		}
	}
	&--products {
		> a:nth-child(4):after {
			background: $color-lightblue;
		}
	}
	&--contact {
		> a:nth-child(5):after {
			background: $color-lightblue;
		}
	}
}

.js-mobile-nav-open,
.js-mobile-nav-open body {
	max-height: 100%;
	overflow: hidden;
}

@media only screen and (max-width: 719px) {
	.site-nav > a  {
		font-size:1rem;
		padding:0.6em;
	}
}