.heading {
	font-weight: 400;
	line-height: 1.1;
	margin-bottom: 0.5em;

	&--hero {
		font-size: $heading-hero;
		font-size: var(--heading-hero);
	}

	&--section {
		font-size: $heading-section;
		font-size: var(--heading-section);
	}

	&--card {
		font-size: $heading-card;
		font-size: var(--heading-card);
	}
}
