.flexible-media {
	height: 0;
	position: relative;
	width: 100%;

	> * {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&--16x9 {
		padding-top: calc(9/16 * 100%);
	}
	&--16x6 {
		padding-top: calc(6/16 * 100%);
	}
	&--4x3 {
		padding-top: calc(3/4 * 100%);
	}
	&--sq {
		padding-top: calc(100%);
	}
}
