.wrapper {
	@extend .clearfix;
	max-width: $site-width;
	margin: 0 auto;
	position: relative;
	padding: 0 1em;

	@include mq($m) {
		padding: 0 2em;
	}

	&--mailchimp {
		background-color: $color-lightgrey;
		max-width: 100%;
		margin-bottom: 2em;
		padding: 2.25rem;
	}
}

.global-wrapper {
	position: relative;
	z-index: 1;
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.main {
	flex: 1 0 auto;
	-ms-flex-preferred-size: auto;
}

.site-header,
.site-footer {
	flex-shrink: 0;
}

body.env--staging:before {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 4px;
	background: #000 url(../images/dev-mode.svg) repeat-x;
}