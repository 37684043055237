.feature-card {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	> .btn {
		align-self: flex-end;
	}

	&__heading {
		color: $color-blue;
	}

	&__description {
		color: $color-lightblue;
		margin-bottom: 1em;
	}

	&__list {
		border-top: 4px solid transparentize($color-grey, 0.25);
		padding: 1em 0 1.25em;

		> li {
			padding: 0.75em 0;
			border-bottom: 1px solid solid transparentize($color-grey, 0.25);
		}
	}
}
