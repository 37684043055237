.quote-form {
	background-color: $color-lightblue;
	border-radius: 0.25em;
	color: $color-white;
	padding: 2em 2.5em;

	&__powered {
		color: $color-blue;
	}

	.btn {
		background-color: $color-blue;
	}

	a {
		color: $color-white;
	}
}
