.legal {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	font-size: 0.8em;
	justify-content: space-between;
	margin-bottom: 2em;

	@include mq($m) {
		flex-direction: row;
	}
}
