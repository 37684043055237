.section {
	padding: $heading-hero 0;
	padding: var(--heading-hero) 0;

	&--alt {
		background-color: $color-lightgrey;
	}

	&--dark {
		background-color: $color-blue;
		color: $color-white;
	}
}
