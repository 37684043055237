.social-links {
	align-items: stretch;
	display: flex;
	justify-content: flex-end;
	margin-left: 1em;

	&__item {
		display: block;
		height: 2em;
		width: 2em;

		& + & {
			margin-left: 0.5em;
		}
	}
}
