.media {
	align-items: flex-start;
	display: flex;

	&--rtl {
		flex-direction: row-reverse;
	}

	&__fig {
		margin-right: 1em;
	}
}
