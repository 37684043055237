.logo {
	display: block;
	float: left;
	width: 150px;

	// @include mq($s) {
	// 	width: 200px;
	// }

	@include mq($m) {
		width: 200px;
	}

	@include mq($m) {
		width: 250px;
	}
}
