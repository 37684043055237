.email-signup {
  display: flex;
  flex-direction: column;
  margin: 1em 0;
  &__inner {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  &__terms{
    font-size: 0.8em;
    margin-top: 1em;
    display: flex;
    width: 100%;
  }

  &__inputs-pair-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .clear {
    padding-left: 0;
    padding-bottom: 0.5em;
    @include mq($m) {
      padding-bottom: 0em;
      padding-left: 1em;
    }
  }

  &__signup-cont {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include mq($m) {
      flex-direction: row;
    }
  }

  &__error-container {
    text-align: center;
    display: none;
    width: 100%;
    margin: 0.5em 0 1em;
    font-size: 0.8em;
    color: #FF4747;
    &--visible {
      display: block;
    }
  }

  &__success-container {
    text-align: center;
    display: none;
    width: 100%;
    margin: 0.5em 0 1em;
    color: $color-blue;
    &--visible {
      display: block;
    }
  }

  &__terms-box {
    display: block;
  }
  &__terms-box-text {
    display: block;
  }

  h2 {
    text-align: center;
  }
  
  input.email {
    font-size: 15px;
    border: 1px solid #ABB0B2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #343434;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0px 0.4em;
    display: inline-block;
    margin: 0;
    margin: 0 1em;
    width: 350px;
    height: 50px;
    vertical-align: top;
    margin-bottom: 1em;
    @include mq($m) {
      margin-bottom: 0em;
    }
  }

}